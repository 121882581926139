import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

import { V1_AUTH_COMPANY_FEATURES, V1_AUTH_USER_ROLE } from '@app/models/api/v1/auth/auth.enums';
import { selectUser } from '../store/selectors/user.selectors';

@Injectable()
export class PermissionService {
  public readonly store = inject(Store);
  public readonly user$ = this.store.select(selectUser);

  //
  // ROLES
  //

  public isOwner(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.OWNER);
  }

  public isAdmin(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN);
  }

  public isManager(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.MANAGER);
  }

  public isAdminOrOwner(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER);
  }

  public isAdminOrOwnerOrManager(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.MANAGER);
  }

  public isTerminal(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.TERMINAL);
  }

  public isSloneekAdminOrTranslator(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.SLONEEK_ADMIN, V1_AUTH_USER_ROLE.SLONEEK_TRANSLATOR);
  }

  public isSloneekTranslator(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.SLONEEK_TRANSLATOR);
  }

  public isSloneekAdmin(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.SLONEEK_ADMIN);
  }

  public isAssetManager(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ASSET_MANAGER);
  }

  public isEvaluationManager(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.EVALUATION_MANAGER);
  }

  //
  // MODULE ACCESS
  //

  public canAccessEngagementModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.ENGAGEMENT);
  }

  public canAccessPeopleAnalyticsModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.PEOPLE_ANALYTICS);
  }

  public canAccessAbsencesModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.ABSENCES);
  }

  public canAccessPropertyModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.PROPERTY);
  }

  public canAccessPlanningModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.PLANNING);
  }

  public canAccessDocumentsModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.DOCUMENTS);
  }

  public canAccessDocumentsV2Module(): Observable<boolean> {
    return combineLatest([
      this.canAccessModule(V1_AUTH_COMPANY_FEATURES.DOCUMENTS),
      this.user$.pipe(map(user => user?.company?.are_new_documents_allowed ?? false)),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  public canAccessCoreExtendedModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.CORE_EXTENDED);
  }

  public canAccessChecklistModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.CHECKLIST);
  }

  public canAccessSkillSetModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.SKILL_SET);
  }

  public canAccessCompetencyModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.COMPETENCE);
  }

  public canAccessQuestionnaireModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.QUESTIONNAIRE);
  }

  public canAccessPerformanceModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.PERFORMANCE);
  }

  public canAccessProjectModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.PROJECT);
  }

  public canAccessPlanningAndProjectModule(): Observable<boolean> {
    return combineLatest([this.canAccessPlanningModule(), this.canAccessProjectModule()]).pipe(
      map(permissions => permissions.every(Boolean)),
    );
  }

  public canAccessSurveysModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.SURVEYS);
  }

  public canAccessAttendanceModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.ATTENDANCE);
  }

  public canAccessSettingsModule(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ADMIN);
  }

  public canAccessAtsModule(): Observable<boolean> {
    return this.canAccessModule(V1_AUTH_COMPANY_FEATURES.ATS);
  }

  public canAccessAtsV2Module(): Observable<boolean> {
    return combineLatest([
      this.canAccessAtsModule(),
      this.hasRoles(
        V1_AUTH_USER_ROLE.ADMIN,
        V1_AUTH_USER_ROLE.OWNER,
        V1_AUTH_USER_ROLE.ATS_HIRING_MANAGER,
        V1_AUTH_USER_ROLE.ATS_ADMINISTRATOR,
      ),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  public canAccessApprovalsModule(): Observable<boolean> {
    return combineLatest([
      combineLatest([this.canAccessPlanningApprovalsByRole(), this.canAccessPlanningModule()]).pipe(
        map(permissions => permissions.every(Boolean)),
      ),
      combineLatest([this.canAccessAbsenceApprovalsByRole(), this.canAccessAbsencesModule()]).pipe(
        map(permissions => permissions.every(Boolean)),
      ),
      this.canAccessDocumentV2Approvals(),
      this.canAccessSettingsModule(),
      this.canAccessAtsJobApprovals(),
    ]).pipe(map(permissions => permissions.some(Boolean)));
  }

  public canAccessEventsModule(): Observable<boolean> {
    return combineLatest([
      this.canAccessAttendanceModule(),
      this.canAccessAbsencesModule(),
      this.canAccessPlanningModule(),
      this.canAccessApprovalsModule(),
      this.canAccessReminderEvents(),
    ]).pipe(map(permissions => permissions.some(Boolean)));
  }

  //
  // COMPANY MODULES
  //

  public canCompanyAccessPerformanceModule(): Observable<boolean> {
    return this.user$.pipe(map(user => user?.company?.grouped_module_settings?.performance?.is_company_performance_enabled ?? false));
  }

  //
  // MODULE MANAGEMENT
  //

  public canManageChecklists(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ADMIN);
  }

  public canManageProperty(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ASSET_MANAGER);
  }

  public canManageDocuments(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.DOCUMENT_MANAGER);
  }

  /**
   * Differs from `canManageDocuments` by checking if the user can see the documents management module by accessing
   * the `can_see_documents_management` property which is related to folder persmissions and DocumentsV2 only.
   */
  public canManageDocumentsWithGrantedUsers(): Observable<boolean> {
    return combineLatest([this.canManageDocuments(), this.user$.pipe(map(user => user?.can_see_documents_management ?? false))]).pipe(
      map(permissions => permissions.some(Boolean)),
    );
  }

  public canManageSurveys(): Observable<boolean> {
    return combineLatest([this.canAccessSettingsModule(), this.canAccessSurveysModule()]).pipe(
      map(permissions => permissions.every(Boolean)),
    );
  }

  public canManagePerformance(): Observable<boolean> {
    return combineLatest([
      this.canAccessPerformanceModule(),
      combineLatest([this.isAdminOrOwnerOrManager(), this.isEvaluationManager()]).pipe(map(permissions => permissions.some(Boolean))),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  public canManagePlanningAndProject(): Observable<boolean> {
    return combineLatest([
      this.canAccessPlanningAndProjectModule(),
      this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.PROJECT_ADMINISTRATOR),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  public canManageEvaluation(): Observable<boolean> {
    return combineLatest([
      this.canAccessSkillSetModule(),
      this.canAccessQuestionnaireModule(),
      this.canAccessPerformanceModule(),
      this.isEvaluationManager(),
    ]).pipe(map(permissions => permissions.some(Boolean)));
  }

  //
  // OTHERS
  //

  public canAccessEvaluationSettings(): Observable<boolean> {
    return combineLatest([this.canAccessSettingsModule(), this.isEvaluationManager()]).pipe(map(permissions => permissions.some(Boolean)));
  }

  public canAccessPlanningApprovalsByRole(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.MANAGER, V1_AUTH_USER_ROLE.PROJECT_MANAGER);
  }

  public canAccessAbsenceApprovalsByRole(): Observable<boolean> {
    return this.user$.pipe(
      switchMap(() =>
        this.hasRoles(
          V1_AUTH_USER_ROLE.OWNER,
          V1_AUTH_USER_ROLE.ADMIN,
          V1_AUTH_USER_ROLE.MANAGER,
          V1_AUTH_USER_ROLE.PROJECT_MANAGER,
          V1_AUTH_USER_ROLE.ABSENCE_APPROVER,
        ),
      ),
    );
  }

  /**
   * Determines whenever user can access approval section for V2 documents.
   * User needs to have one of the following roles: OWNER, ADMIN, DOCUMENT_MANAGER.
   * And the company needs to have the new documents feature enabled.
   */
  public canAccessDocumentV2Approvals(): Observable<boolean> {
    return combineLatest({
      canAccessV2DocumentsModule: this.canAccessDocumentsV2Module(),
      hasRequiredRole: this.user$.pipe(
        switchMap(() => this.hasRoles(V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.DOCUMENT_MANAGER)),
      ),
    }).pipe(map(({ hasRequiredRole, canAccessV2DocumentsModule }) => hasRequiredRole && canAccessV2DocumentsModule));
  }

  public canAccessAtsSettings(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.ATS_ADMINISTRATOR);
  }

  public canAccessAtsJobApprovals(): Observable<boolean> {
    return this.user$.pipe(map(user => user?.has_user_awaiting_job_offer_approvals ?? false));
  }

  public canAccessReminderEvents(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.ASSET_MANAGER, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.MANAGER);
  }

  public canAccessAssetReminderEvents(): Observable<boolean> {
    return combineLatest([
      this.canAccessPropertyModule(),
      this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.ASSET_MANAGER, V1_AUTH_USER_ROLE.OWNER),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  public canAccessUserReminderEvents(): Observable<boolean> {
    return this.hasRoles(V1_AUTH_USER_ROLE.ADMIN, V1_AUTH_USER_ROLE.OWNER, V1_AUTH_USER_ROLE.MANAGER);
  }

  public canAccessTrustBox(): Observable<boolean> {
    return this.user$.pipe(map(user => !user?.company?.application_settings?.is_hide_trust_box ?? false));
  }

  public canAccessCompanyStructure(): Observable<boolean> {
    return combineLatest([
      this.canAccessSettingsModule(),
      this.user$.pipe(map(user => user?.company?.application_settings?.is_allow_to_see_team_structure_to_all_employees ?? false)),
    ]).pipe(map(permissions => permissions.some(Boolean)));
  }

  public canAccessTrustBoxMessages(): Observable<boolean> {
    return combineLatest([
      this.canAccessTrustBox(),
      this.user$.pipe(
        switchMap(user =>
          user?.company?.has_company_ombudsman ? this.hasRoles(V1_AUTH_USER_ROLE.OMBUDSMAN) : this.canAccessSettingsModule(),
        ),
      ),
    ]).pipe(map(permissions => permissions.every(Boolean)));
  }

  //
  // UTILS
  //

  public hasRoles(...roles: V1_AUTH_USER_ROLE[]): Observable<boolean> {
    return this.user$.pipe(map(user => user?.roles.some(role => roles.includes(role)) ?? false));
  }

  public hasAllRoles(...roles: V1_AUTH_USER_ROLE[]): Observable<boolean> {
    return this.user$.pipe(map(user => roles.every(role => !!user?.roles.includes(role))));
  }

  public canAccessModule(module: V1_AUTH_COMPANY_FEATURES): Observable<boolean> {
    return this.user$.pipe(map(user => user?.modules[module] ?? false));
  }

  public isModuleAccessible(module: V1_AUTH_COMPANY_FEATURES): Observable<boolean> {
    return this.user$.pipe(map(user => user?.company?.modules_accessibility[module] ?? false));
  }
}
